window.onload = function() {
    var videoElement = document.getElementById('first-screen-video');
    var otherVideos = document.querySelectorAll('video:not(#first-screen-video)');

    if (videoElement) {
        var source = document.createElement('source');
        if (window.innerWidth <= 450) {
            source.src = '/assets/home/first-screen-video-new-mobile.mp4';
        } else {
            source.src = '/assets/home/first-screen-video-new.mp4';
        }
        source.type = 'video/mp4';
        videoElement.appendChild(source);

        videoElement.muted = true;  // Забезпечує автозапуск
        videoElement.playsInline = true;
        videoElement.load();

        videoElement.addEventListener('canplay', function () {
            videoElement.play().catch(function (error) {
                console.error('Autoplay failed:', error);

                document.body.addEventListener('click', function () {
                    videoElement.play().catch(function (err) {
                        console.error('Play failed on user interaction:', err);
                    });
                }, { once: true });
            });
        });

        // Специфіка для WeChat
        document.addEventListener('WeixinJSBridgeReady', function () {
            videoElement.play().catch(function (error) {
                console.error('WeChat autoplay issue:', error);
            });
        });
    }
    if (otherVideos) {
      otherVideos.forEach(function(otherVideo) {
          otherVideo.muted = true;
          otherVideo.playsInline = true;
          otherVideo.load();

          otherVideo.addEventListener('canplay', function () {
              otherVideo.play().catch(function (error) {
                  console.error('Autoplay failed:', error);

                  document.body.addEventListener('click', function () {
                      otherVideo.play().catch(function (err) {
                          console.error('Play failed on user interaction:', err);
                      });
                  }, { once: true });
              });
          });

          document.addEventListener('WeixinJSBridgeReady', function () {
              otherVideo.play().catch(function (error) {
                  console.error('WeChat autoplay issue:', error);
              });
          });
      });
  }
  };
