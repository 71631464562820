document.addEventListener('DOMContentLoaded', function() {
    var bannerElement = document.querySelector('.banner-popup');

    if (bannerElement) {
        if (!sessionStorage.getItem('popupShown')) {
            var bannerBgElement = document.querySelector('.banner-popup-bg');
            var bannerClsBtn = document.querySelector('.close-btn-banner');

            var closePopup = function() {
                bannerBgElement.classList.remove('active');
                bannerElement.classList.remove('active');
            };

            bannerBgElement.addEventListener('click', closePopup);
            bannerClsBtn.addEventListener('click', closePopup);

            setTimeout(function() {
                bannerBgElement.classList.add('active');
                bannerElement.classList.add('active');
                sessionStorage.setItem('popupShown', 'true');
            }, 5000);
        }
    }
});
