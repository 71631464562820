import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-selector"
export default class extends Controller {
  connect() {
    var innerImgs = document.querySelectorAll('.inner-img');
    var showImageBox = document.querySelector('.show-image-box img');
    var closeButton = document.querySelector('.close-button');
    var showOpen = document.querySelector('.show.open');

    innerImgs[0].classList.add('active');
    showImageBox.setAttribute('src', innerImgs[0].querySelector('img').getAttribute('src'));

    innerImgs.forEach(function (innerImg) {
      innerImg.addEventListener('click', function () {
      innerImgs.forEach(function (img) {
        img.classList.remove('active');
      });

      innerImg.classList.add('active');

      var largeImageUrl = innerImg.querySelector('img').getAttribute('src');
      showImageBox.setAttribute('src', largeImageUrl);
      showOpen.classList.add('active');
      });
    });

    closeButton.addEventListener('click', function () {
      showOpen.classList.remove('active');
    });
  }
}
