import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  toggleMainCurrency(event) {
    const currencyId = event.params.index;
    Rails.ajax({
      type: "patch",
      url: `currencies/${currencyId}`,
      data: `target=main_currency`,
    });
  }

  toggleActive(event) {
    const currencyId = event.params.index;
    Rails.ajax({
        type: "patch",
        url: `currencies/${currencyId}`,
        data: `target=active`,
    });
  }

  updateSession(event) {
    const selectedCurrencyCode = event.target.value;

    Rails.ajax({
      type: 'POST',
      url: '/update_currency_session',
      data: `currency_id=${selectedCurrencyCode}`,
      success: () => {
        location.reload();
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}
