document.addEventListener("turbo:load", function(){
  const anchors = document.querySelectorAll('a[href*="#"]:not(.no-scroll)')

  for (let anchor of anchors) {
    anchor.addEventListener('click', function (e) {
      e.preventDefault()
      const blockID = anchor.getAttribute('href').substr(1)

      const targetElement = document.getElementById(blockID)
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    })
  }
});
